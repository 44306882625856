.carousel.carousel-slider {
    height: 0;
    padding-top: 56.25%;
}

.carousel .slider-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}


.carousel .slide>div {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    background-color: #f4f4f4;

}

.carousel .slide div img {
    height: 100%;
    width: 100%;
    position: absolute;
    object-fit: contain;
    right: 0;
    left: 0;
}

.sliderHtml {
    width: 100%;
    position: absolute;
    height: 100%;
}

.innerHtml {
    height: auto;
    padding: 0;
    width: 100%;
}

.carousel .slide iframe {
    width: 99.99% !important;
    margin: 0;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.videoInstitucional {
    position: relative;
    width: 99.99% !important;
    margin: 0;
    height: 0;
    padding-bottom: 56.25%;
}

.videoInstitucional iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}