@font-face {
  font-family: 'Foco Regular';
  src: url('../fonts/focoRegular.ttf') format('truetype');
}

@font-face {
  font-family: 'Foco Bold';
  src: url('../fonts/focoBold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Foco Light';
  src: url('../fonts/focoLight.ttf') format('truetype');
}

.hidden {
  opacity: 0;
}

.formError {
  color: #f44336;
}

.formSelectErrorMessage {
  color: #f44336;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%
    /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}