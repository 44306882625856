// Tables
// ==========================================================================

//
// Remove most spacing between table cells.
//

.table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table td,
.table th {
  padding: 0;
}
